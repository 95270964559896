import React from "react"
import anime from "animejs"

const defaultState = {
  isOpen: false,
  toggleForm: () => {},
  openForm: () => {},
  closeForm: () => {},
}

const FormContext = React.createContext(defaultState)

export class FormProvider extends React.Component {
  state = {
    isOpen: false,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
  }

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen }, this.animate)
  }

  animate = () => {
    const container = document.getElementById("popup")
    const form = container.querySelector("form")
    const overlay = container.querySelector("div")
    const formAnimation = anime.timeline({
      easing: "easeOutExpo",
      duration: 900,
    })
    formAnimation
      .add({
        targets: form,
        translateY: ["-50%", 0],
        opacity: [0, 1],
      })
      .add({
        targets: form.querySelectorAll(".form-row"),
        translateY: [100, 0],
        opacity: [0, 1],
        delay: anime.stagger(150),
      })

    anime({
      targets: overlay,
      opacity: [0, 1],
      easing: "easeOutQuad",
      duration: 700,
    })
  }

  openForm = () => {
    this.lockScrollBar()
    this.setState({ isOpen: true }, this.animate)
  }

  closeForm = event => {
    event.preventDefault()
    this.unlockScrollBar()
    const container = document.getElementById("popup")
    const form = container.querySelector("form")
    const overlay = container.querySelector("div")
    const formAnimation = anime.timeline({
      easing: "easeOutQuad",
      duration: 900,
      delay: 0,
    })
    const targets = form.querySelectorAll(".form-row")
    if (targets.length !== 0) {
      formAnimation.add({
        targets: targets,
        translateY: 100,
        opacity: 0,
        delay: anime.stagger(50),
        complete: () => {
          anime({
            targets: overlay,
            opacity: 0,
            duration: 900,
            delay: 100,
          })
        },
      })
    } else {
      anime({
        targets: overlay,
        opacity: 0,
        duration: 900,
        delay: 100,
      })
    }
    formAnimation.add({
      targets: form,
      translateY: "-50%",
      opacity: 0,
      complete: () => {
        this.setState({ isOpen: false })
      },
    })
  }

  lockScrollBar = () => {
    document.body.style.overflow = "hidden"
  }

  unlockScrollBar = () => {
    document.body.style.overflow = null
  }

  render() {
    const { children } = this.props
    const { isOpen } = this.state
    return (
      <FormContext.Provider
        value={{
          isOpen,
          toggleForm: this.toggleForm,
          openForm: this.openForm,
          closeForm: this.closeForm,
        }}
      >
        {children}
      </FormContext.Provider>
    )
  }
}

export default FormContext
