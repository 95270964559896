import React from "react"
import tw, { styled, css } from "twin.macro"
import Input from "./form-input"
import FormContext from "./popup-form-context"
import Button from "./button"
import anime from "animejs"

const fullscreenSize = 800

const Title = tw.h2`font-mont text-2xl mb-2 font-bold text-center`
const Subtitle = tw.p`font-mont text-base mb-8 font-bold text-center text-marrone-primary`
const Overlay = styled.div(() => [
  tw`absolute z-behind top-0 opacity-0 left-0 bg-black w-full h-full`,
])
const CloseButton = styled.button(() => [
  tw`absolute text-sm text-white font-mont font-bold uppercase tracking-widest`,
  css`
    top: -2rem;
    right: 0;

    @media (max-width: ${fullscreenSize}px) {
      ${tw`text-black`}
      top: 1rem;
      right: 1rem;
    }
  `,
])

const Container = styled.div(() => [
  tw`z-excessive w-full h-full fixed top-0 left-0 flex items-center justify-center`,
  css`
    &:not(.active) {
      ${tw`pointer-events-none`}
    }
  `,
])

const Content = styled.div(() => [
  tw`p-6 overflow-x-hidden overflow-y-auto py-12 bg-white flex flex-col items-center justify-center`,
  css`
    min-height: 85vh;
    max-height: 90vh;
    min-width: 700px;

    @media (max-width: ${fullscreenSize}px) {
      ${tw`w-full h-full px-4 pt-16 pb-4 justify-start`}
      min-width: 0;
      max-height: 100vh;
    }
  `,
])

const Divider = styled.div(() => [
  tw`bg-marrone-gray w-full flex my-8`,
  css`
    max-width: 450px;
    height: 1px;
  `,
])

const Form = styled.form(() => [
  tw`bg-white z-excessive absolute`,
  css`
    max-width: 700px;

    textarea {
      ${tw`border-l-0 border-r-0 border-t-0`}
      height: 120px;
    }

    input,
    textarea {
      ${tw`border-marrone-primary mt-0`}
    }

    &:before {
      ${tw`absolute`}
      background: #5d5330;
      z-index: -1;
      content: "";
      left: -5rem;
      top: 10%;
      height: 95%;
      width: 350px;
    }

    @media (max-width: ${fullscreenSize}px) {
      ${tw`bg-white top-0 left-0 w-full h-full`}
      max-width: none;
      &:before {
        ${tw`hidden`}
      }
    }

    .solid-button {
      ${tw`mt-4`}
    }
  `,
])
const Response = tw.p`font-bold transition-all duration-500 ease-in-out font-mont text-center w-full overflow-hidden max-h-0`

export default class PopupForm extends React.Component {
  state = {
    isComplete: false,
  }
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formRef = React.createRef()
    this.responseRef = React.createRef()
  }

  validate() {
    const form = this.formRef.current
    const inputs = form.querySelectorAll("input,textarea,select")
    let errors = 0
    inputs.forEach(input => {
      const error = input.parentElement.querySelector(".error")
      if (!input.validity.valid) {
        if (error) error.classList.add("active")
        input.setAttribute("aria-invalid", true)
        errors++
      } else {
        if (error) error.classList.remove("active")
        input.removeAttribute("aria-invalid")
      }
    })
    return errors === 0
  }

  completeForm() {
    const content = this.formRef.current.querySelector(".form-content")
    const targets = content.querySelectorAll(".form-row, h2, p")
    anime({
      targets: targets,
      translateX: "-150%",
      opacity: 0,
      easing: "easeOutExpo",
      duration: 900,
      complete: () =>
        this.setState({ isComplete: true }, () => {
          anime({
            targets: content.querySelector("h2"),
            translateX: 0,
            duration: 0,
          })
          anime({
            targets: content.querySelectorAll("*"),
            translateY: ["100%", 0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 900,
          })
        }),
    })
    anime({
      targets: ".solid-button",
      opacity: 0,
      duration: 900,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    const response = this.responseRef.current
    if (this.validate()) {
      const form = event.target.closest("form")
      fetch(`${form.action}.json`, {
        method: "POST",
        body: new FormData(form),
        headers: new Headers({
          Accept: "application/json",
        }),
      })
        .then(res => res.json())
        .then(data => {
          const event = new Event("nextlevel_email_sent")
          document.dispatchEvent(event)
          if (process.env.NODE_ENV === "development")
            console.log("Basin response:", data)
          this.completeForm()
        })
        .catch(err => {
          response.innerText =
            "There was an error submitting your form. Please try again."
          response.style.maxHeight = `calc(${response.scrollHeight}px + 2rem)`
          response.style.paddingTop = `2rem`
          response.style.color = "#F56565"
          console.error(err)
        })
    } else {
      response.style.maxHeight = null
      response.style.paddingTop = null
    }
  }

  render() {
    return (
      <FormContext.Consumer>
        {form => (
          <Container
            id="popup"
            className={form.isOpen && "active"}
            ref={form.formRef}
          >
            {form.isOpen && (
              <>
                <Overlay onClick={form.closeForm} />
                <Form
                  ref={this.formRef}
                  action="https://usebasin.com/f/28f28c984d2c"
                  method="post"
                >
                  <CloseButton onClick={form.closeForm}>
                    &times; CLOSE
                  </CloseButton>
                  <Content className="form-content">
                    {this.state.isComplete ? (
                      <>
                        <Title>THANK YOU!</Title>
                        <Subtitle tw="mb-0">
                          Someone will be in touch within 24 hours!
                        </Subtitle>
                        <Divider />
                        <Subtitle tw="text-black mb-0">
                          IN THE MEANTIME
                        </Subtitle>
                        <div tw="flex flex-col items-center justify-center">
                          <Button
                            className="solid-button"
                            to="/firm-overview/case-results"
                          >
                            check out our case results
                          </Button>
                          <Subtitle tw="text-black mb-0 mt-4">OR</Subtitle>
                          <Button
                            className="solid-button"
                            to="/firm-overview/reviews/"
                          >
                            see what our clients are saying
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Title>FREE CASE EVALUATION</Title>
                        <Subtitle>
                          All fields are required so we can help!
                        </Subtitle>
                        <Input
                          id="popup-name"
                          name="Name"
                          label="Name"
                          errorMsg="Please provide us with your name."
                          required
                        />
                        <Input
                          id="popup-email"
                          type="email"
                          label="Email"
                          name="Email"
                          errorMsg="Please enter a valid email address."
                          required
                        />
                        <Input
                          id="popup-phone"
                          type="phone"
                          label="Phone"
                          name="Phone"
                          errorMsg="Please enter a valid phone number."
                          required
                        />
                        <Input
                          id="popup-msg"
                          label="Brief description of your case"
                          textarea
                          name="Message"
                          required
                          errorMsg="Please tell us a little more. "
                        />
                        <Button
                          className="solid-button"
                          onClick={this.handleSubmit}
                        >
                          request your consultation
                        </Button>
                        <Response ref={this.responseRef}>
                          Thank you for your submission! Someone from our office
                          will be in touch with you shortly.
                        </Response>
                      </>
                    )}
                  </Content>
                </Form>
              </>
            )}
          </Container>
        )}
      </FormContext.Consumer>
    )
  }
}

export const PopupButton = props => (
  <FormContext.Consumer>
    {form => <Button {...props} onClick={form.openForm} />}
  </FormContext.Consumer>
)
